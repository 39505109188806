import React from 'react';
//import styled from 'styled-components';
import { Page, PageContent, PageHeader, Footer, Text } from 'grommet';
//import { Hpe as HpeIcon } from 'grommet-icons';
import Iframe from 'react-iframe';
import { AppFooter } from '../components';

const HomePage = () => {

  //var perf =require('../content/crowdstrike/Instructions.htm');
  //const [value, setValue] = React.useState('');
  //const size = useContext(ResponsiveContext);
  //const [focused] = useState(false);
  //const [focused, setFocused] = useState(false);

  return (

    <Page background="background-back" fill>

      <PageContent fill>
        <PageHeader title="CrowdStrike Issue Guidance" size="small"
          pad={{ vertical: 'small' }}
          subtitle={`Follow the resolution paths in the order below.`}
        />

        <Iframe url="https://cs-guide.pages.dev/"
          allowFullScreen
          width="100%"
          height="100%"
          frameBorder="0"
          id=""
          className=""/>

      </PageContent>

      <Footer pad="xsmall" justify="center">
        <Text size="11pt" style={{fontStyle: 'italic', fontWeight: 'bold'}}>Powered by HPE IT</Text>
      </Footer>

      <AppFooter />

    </Page>

  );
};

export { HomePage as Home };