import React, { useState } from "react";
import { Notification, Collapsible } from 'grommet';

const BannerNotification = () => {

  const [showNotification, setShowNotification] = useState(true);

  return (
    <Collapsible open={showNotification}>
      <Notification
        status="critical"
        title="CrowdStrike Issue"
        margin={{ top: '5px' }}
        onClose={() => setShowNotification(false)}

        message="Please use only the resolution instructions on this site- thank you!"
        global
      />
    </Collapsible>
   );
};

export { BannerNotification as Notification };